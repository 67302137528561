import http from '../http';
import { queryToUrl } from '@/utils';

// GET
export const getProductsList = config => {
  const url = '/api/v1/products';
  return http.get(url, config);
};

export const getProductById = (id, config) => {
  const url = `/api/v1/products/${id}`;
  return http.get(url, config);
};

export const getProductPerformanceChartData = (id, query, config) => {
  let url = `/api/v1/products/${id}/performance`;
  if (query) url = queryToUrl(url, query);
  return http.get(url, config);
};

export const getAdvisorProductCommissionsList = (id, config) => {
  const url = `/api/v1/products/advisors/${id}/commission_list`;
  return http.get(url, config);
};

export const getAdvisorProductCommissions = (id, config) => {
  const url = `/api/v1/products/${id}/commissions`;
  return http.get(url, config);
};
