import { Form as VeeForm, defineRule, configure } from 'vee-validate';

import AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import { isPast } from 'date-fns';

configure({
  generateMessage: localize({
    en,
  }),
});

setLocale('en');

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});

defineRule('password', v => {
  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{9,}/;
  if (v.match(regex)) {
    return true;
  } else {
    return 'The password must contain at least one small letter, one capital letter, and one number';
  }
});

defineRule('dateInFuture', (v, params, ctx) => {
  if (isPast(v)) {
    return `The ${ctx.field} field must be in the future`;
  } else {
    return true;
  }
});

export default VeeForm;
