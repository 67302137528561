import http from '../http';

// GET
export const getInvestorDashboardStats = (profileId, config) => {
  const url = `/api/v1/statistics/investors/${profileId}/total_amount_invested`;
  return http.get(url, config);
};

export const getCompanyDashboardStats = (profileId, config) => {
  const url = `/api/v1/statistics/companies/${profileId}/total_amount_invested`;
  return http.get(url, config);
};

export const getAdvisorDashboardStats = (profileId, config) => {
  const url = `/api/v1/statistics/advisors/${profileId}`;
  return http.get(url, config);
};
