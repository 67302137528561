import NProgress from 'nprogress';

export default {
  install(app) {
    NProgress.configure({
      showSpinner: false,
    });
    app.config.globalProperties.$loader = NProgress;
  },
};
