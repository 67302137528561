import Axios from 'axios';
import Cookies from 'js-cookie';
import { addHours } from 'date-fns';
import { useAppStore } from '@/stores/AppStore';
import router from '@/router';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

axios.interceptors.request.use(
  request => {
    const appStore = useAppStore();
    const accessToken = appStore.token;
    const uid = appStore.user.uid;
    const client = appStore.user.client;

    if (accessToken) {
      request.headers.uid = uid;
      request.headers.client = client;
      request.headers['access-token'] = accessToken;
    }

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    // Always update access token on every response
    // https://devise-token-auth.gitbook.io/devise-token-auth/conceptual
    const appStore = useAppStore();

    const accessToken = response.headers['access-token'];
    const expiry = response.headers['expiry'];
    const rememberMe = Cookies.get('remember-me');
    let expires;

    if (rememberMe) {
      expires = new Date(+expiry * 1000);
      appStore.rememberMe = true;
      Cookies.set('remember-me', true, { expires });
    } else {
      expires = addHours(new Date(), 2);
    }

    if (accessToken) {
      appStore.setToken(accessToken);
      Cookies.set('access-token', accessToken, { expires });
    }

    return response;
  },
  error => {
    const appStore = useAppStore();
    const message = error.response?.data?.message;
    const isUnauthorized = error.response?.status === 401;

    if (isUnauthorized) {
      appStore.logout();
      router.replace({ name: 'login-page' });
    } else {
      // eslint-disable-next-line no-console
      console.error(message);
    }
    return Promise.reject(error);
  }
);

export default axios;
