import { createApp } from 'vue';
import pinia from './store';
import App from './App.vue';
import router from './router';
import VeeForm from './vee-validate';
import Notifications from '@kyvg/vue3-notification';
import Breakpoint from '@/plugins/breakpoint';
import Nprogress from '@/plugins/nprogress';
import VueNotifications from '@/plugins/vue-notifications';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Filler,
  CategoryScale,
  LinearScale,
} from 'chart.js';

import '@/assets/scss/main.scss';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import 'vue-select/dist/vue-select.css';
import 'highlight.js/styles/monokai.css';

const app = createApp(App);

app.component('VeeForm', VeeForm);

app.use(pinia);
app.use(router);
app.use(Notifications);
app.use(Breakpoint);
app.use(Nprogress);
app.use(VueNotifications);

ChartJS.register([
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
]);

app.mount('#app');
