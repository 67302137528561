import http from '../http';

// POST
export const postRequestMeeting = (body, config) => {
  const url = '/api/v1/admin_notifications/meeting_request';
  return http.post(url, body, config);
};

export const postWithdrawInvestment = (body, config) => {
  const url = '/api/v1/admin_notifications/withdraw_investment';
  return http.post(url, body, config);
};
