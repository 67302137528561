import { useNotification } from '@kyvg/vue3-notification';
export default {
  install(app) {
    const notification = useNotification();

    app.config.globalProperties.$notifyInfo = (message, title) => {
      notification.notify({
        title: title || 'Info',
        text: message,
      });
    };

    app.config.globalProperties.$notifyError = (message, title) => {
      notification.notify({
        type: 'error',
        title: title || 'Error',
        text: message || 'Something went wrong. Please try again later.',
      });
    };

    app.config.globalProperties.$notifySuccess = (message, title) => {
      notification.notify({
        type: 'success',
        title: title || 'Success',
        text: message,
      });
    };

    app.config.globalProperties.$notifyWarning = (message, title) => {
      notification.notify({
        type: 'warn',
        title: title || 'Warning',
        text: message,
      });
    };
  },
};
