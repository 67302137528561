import http from '../http';

// GET
export const getAdvisorClients = (profileId, config) => {
  const url = `/api/v1/advisors/${profileId}/clients`;
  return http.get(url, config);
};

export const getAdvisorClientsTaxCerts = (profileId, config) => {
  const url = `/api/v1/advisors/${profileId}/client_latest_docs`;
  return http.get(url, config);
};

// POST
export const postAdvisorClient = (profileId, body, config) => {
  const url = `/api/v1/advisors/${profileId}/clients`;
  return http.post(url, body, config);
};

export const postAdvisorInviteClient = (profileId, body, config) => {
  const url = `/api/v1/advisors/${profileId}/invite_client`;
  return http.post(url, body, config);
};
