<template>
  <div>
    <RouterView />
    <notifications />
  </div>
</template>

<script>
import { RouterView } from 'vue-router';
import { mapStores } from 'pinia';
import { useAppStore } from '@/stores/AppStore';

export default {
  name: 'App',

  components: {
    RouterView,
  },

  computed: {
    ...mapStores(useAppStore),
  },

  beforeMount() {
    this.appStore.appInit();
  },
};
</script>
