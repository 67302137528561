import { createRouter, createWebHistory } from 'vue-router';
import { useAppStore } from '@/stores/AppStore';
import { USER_ROLES } from '@/constants/user-roles';

const router = createRouter({
  history: createWebHistory(import.meta.BASE_URL),
  routes: [
    // Global routes
    {
      path: '/',
      name: 'home',
      // redirect: { name: 'start-page' }
      redirect: { name: 'login-page' },
    },
    // {
    //   path: '/start',
    //   name: 'start-page',
    //   component: () => import('@/components/pages/JStartPage')
    // },
    {
      path: '/login',
      name: 'login-page',
      component: () => import('@/components/pages/JLoginPage'),
    },
    {
      path: '/registration',
      name: 'registration-page',
      component: () => import('@/components/pages/JRegistrationPage'),
    },
    {
      path: '/reset-password',
      name: 'reset-password-page',
      component: () => import('@/components/pages/JResetPasswordPage'),
    },
    {
      path: '/check-email',
      name: 'check-email-page',
      component: () => import('@/components/pages/JCheckForEmailPage'),
    },
    {
      path: '/invitation/accept',
      name: 'invitation-accept-page',
      component: () => import('@/components/pages/JInvitationAcceptPage'),
    },
    {
      path: '/products',
      name: 'products-page',
      component: () => import('@/components/pages/JProductsPage'),
      meta: { requiresAuth: true },
    },
    {
      path: '/products/:id',
      name: 'product-page',
      component: () => import('@/components/pages/JProductPage'),
      meta: { requiresAuth: true },
    },
    // Client's routes
    {
      path: '/client',
      name: 'client',
      redirect: { name: 'client-dashboard-page' },
      children: [
        {
          path: 'dashboard',
          name: 'client-dashboard-page',
          component: () => import('@/components/pages/JClientDashboardPage'),
          meta: { requiresAuth: true, isClient: true },
        },
        {
          path: 'profile',
          name: 'client-profile-page',
          component: () => import('@/components/pages/JClientProfilePage'),
          meta: { requiresAuth: true, isClient: true },
        },
        {
          path: 'existing-investments',
          name: 'client-existing-investments-page',
          component: () =>
            import('@/components/pages/JClientExistingInvestmentsPage'),
          meta: { requiresAuth: true, isClient: true },
        },
        {
          path: 'faq',
          name: 'client-faq-page',
          component: () => import('@/components/pages/JClientFAQPage'),
          meta: { requiresAuth: true, isClient: true },
        },
      ],
    },
    // Financial Advisor's routes
    {
      path: '/financial-advisor',
      name: 'financial-advisor',
      redirect: { name: 'financial-advisor-dashboard-page' },
      children: [
        {
          path: 'dashboard',
          name: 'financial-advisor-dashboard-page',
          component: () => import('@/components/pages/JFADashboardPage'),
          meta: { requiresAuth: true, isAdvisor: true },
        },
        {
          path: 'clients',
          name: 'financial-advisor-clients-page',
          component: () => import('@/components/pages/JFAClientsPage'),
          meta: { requiresAuth: true, isAdvisor: true },
        },
        {
          path: 'clients/:id',
          name: 'financial-advisor-client-page',
          component: () => import('@/components/pages/JFAClientPage'),
          meta: { requiresAuth: true, isAdvisor: true },
        },
        {
          path: 'clients/add-new-client',
          name: 'financial-advisor-add-new-client-page',
          component: () => import('@/components/pages/JFAAddNewClientPage'),
          meta: { requiresAuth: true, isAdvisor: true },
        },
        {
          path: 'commission',
          name: 'financial-advisor-commission-page',
          component: () => import('@/components/pages/JFACommissionPage'),
          meta: { requiresAuth: true, isAdvisor: true },
        },
        {
          path: 'profile',
          name: 'financial-advisor-profile-page',
          component: () => import('@/components/pages/JFAProfilePage'),
          meta: { requiresAuth: true, isAdvisor: true },
        },
        {
          path: 'faq',
          name: 'financial-advisor-faq-page',
          component: () => import('@/components/pages/JFAFAQPage'),
          meta: { requiresAuth: true, isAdvisor: true },
        },
      ],
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found-page',
      component: () => import('@/components/pages/J404Page'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const appStore = useAppStore();
    const token = appStore.token;
    const userRole = appStore.userRole;
    if (!token) {
      next({ name: 'login-page' });
    } else {
      if (to.meta.isAdvisor && userRole !== USER_ROLES.FINANCIAL_ADVISOR) {
        next({ path: '/not-allowed', query: { code: 403 } });
      } else if (
        to.meta.isClient &&
        userRole === USER_ROLES.FINANCIAL_ADVISOR
      ) {
        next({ path: '/not-allowed', query: { code: 403 } });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
