import http from '../http';

// POST
export const postAdvisorWithdrawalInfo = (id, body, config) => {
  const url = `/api/v1/user_notifications/advisor_withdrawal_info/investor/${id}`;
  return http.post(url, body, config);
};
export const postCompanyWithdrawalInfo = (id, body, config) => {
  const url = `/api/v1/user_notifications/company_withdrawal_info/company/${id}`;
  return http.post(url, body, config);
};

export const postInvestorWithdrawalInfo = (id, body, config) => {
  const url = `/api/v1/user_notifications/investor_withdrawal_info/investor/${id}`;
  return http.post(url, body, config);
};
