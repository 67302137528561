import http from '../http';

// POST
export const postRegistration = (body, config) => {
  const url = '/api/v1/auth';
  return http.post(url, body, config);
};

export const postLogin = (body, config) => {
  const url = '/api/v1/auth/sign_in';
  return http.post(url, body, config);
};

export const postResetPassword = (body, config) => {
  const url = '/api/v1/auth/password';
  return http.post(url, body, config);
};

// PUT
export const putResetPassword = (body, config) => {
  const url = '/api/v1/auth/password';
  return http.put(url, body, config);
};

export const putInvitation = (body, config) => {
  const url = '/api/v1/auth/invitation';
  return http.put(url, body, config);
};

// PATCH
export const patchUpdatePassword = (body, config) => {
  const url = '/api/v1/auth/password';
  return http.patch(url, body, config);
};
// DELETE
export const deleteLogout = config => {
  const url = '/api/v1/auth/sign_out';
  return http.delete(url, config);
};
