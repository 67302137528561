import { reactive } from 'vue';

export default {
  install(app, options = {}) {
    const breakpoint = {
      xxs: 375,
      xs: 550,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      ...(options.breakpoint ? options.breakpoint : {}),
    };

    const state = reactive({
      width: null,
      xxs: false,
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      smAndDown: false,
      mdAndDown: false,
      lgAndDown: false,
      xlAndDown: false,
      smAndUp: false,
      mdAndUp: false,
      lgAndUp: false,
      xlAndUp: false,
      xxlAndUp: false,
    });

    function checkWidth() {
      state.width = window.innerWidth;
      state.xxs = state.width < breakpoint.xxs;
      state.xs = state.width < breakpoint.xs;
      state.sm = state.width >= breakpoint.xs && state.width < breakpoint.sm;
      state.md = state.width >= breakpoint.sm && state.width <= breakpoint.md;
      state.lg = state.width > breakpoint.md && state.width < breakpoint.lg;
      state.xl = state.width > breakpoint.lg && state.width < breakpoint.xl;
      state.smAndDown = state.width < breakpoint.sm;
      state.mdAndDown = state.width <= breakpoint.md;
      state.lgAndDown = state.width < breakpoint.lg;
      state.xlAndDown = state.width < breakpoint.xl;
      state.smAndUp = state.width >= breakpoint.xs;
      state.mdAndUp = state.width >= breakpoint.sm;
      state.lgAndUp = state.width > breakpoint.md;
      state.xlAndUp = state.width > breakpoint.lg;
      state.xxlAndUp = state.width > breakpoint.xl;
    }

    checkWidth();

    window.addEventListener('resize', () => {
      checkWidth();
    });

    app.config.globalProperties.$breakpoint = state;
  },
};
