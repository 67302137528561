import { defineStore } from 'pinia/dist/pinia';
import { USER_ROLES } from '@/constants/user-roles';
import { deleteLogout } from '@/services/api/v1';
import Cookies from 'js-cookie';
import { addHours } from 'date-fns';

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    token: null,
    rememberMe: false,
    user: {
      role: USER_ROLES.CLIENT,
      id: '',
      name: '',
      uid: '',
      client: '',
      profileId: '',
      personId: '',
    },
  }),
  getters: {
    userRole: state => state.user.role,
    userName: state => state.user.name,
    isFA: state => state.user.role === USER_ROLES.FINANCIAL_ADVISOR,
    isAuthenticated: state => !!state.token,
  },
  actions: {
    appInit() {
      this.token = Cookies.get('access-token');
      this.user.id = Cookies.get('user-id');
      this.user.role = Cookies.get('user-type');
      this.user.name = Cookies.get('user-name');
      this.user.client = Cookies.get('client');
      this.user.uid = Cookies.get('uid');
      this.user.profileId = Cookies.get('user-profile-id');
      this.user.personId = Cookies.get('user-person-id');
    },
    setUserRole(role) {
      this.user.role = role;
    },
    setToken(token) {
      this.token = token;
    },
    async logout() {
      try {
        await deleteLogout();
      } catch (e) {
        console.error(e);
      } finally {
        Cookies.remove('access-token');
        Cookies.remove('user-type');
        Cookies.remove('client');
        Cookies.remove('uid');
        Cookies.remove('remember-me');
        Cookies.remove('user-name');
        Cookies.remove('user-id');
        Cookies.remove('user-profile-id');
        Cookies.remove('user-person-id');
        this.token = null;
        this.rememberMe = false;
        this.user.id = null;
        this.user.name = '';
        this.user.uid = '';
        this.user.client = '';
        this.user.profileId = '';
        this.user.personId = '';
        this.user.role = USER_ROLES.CLIENT;
      }
    },
    async saveUserData(loginDataResponse, rememberMe) {
      const {
        data: { data: userData },
        headers,
      } = loginDataResponse;

      const accessToken = headers['access-token'];
      const client = headers['client'];
      const uid = headers['uid'];
      const userId = userData.id;
      const userType = userData.profile_type;
      const firstName = userData.person.first_name;
      const lastName = userData.person.last_name;
      const userName = `${firstName} ${lastName}`;
      const profileId = userData.profile_id;
      const personId = userData.person.id;
      let expires;
      if (rememberMe) {
        expires = new Date(Number(headers['expiry']) * 1000);
        Cookies.set('remember-me', true, { expires });
      } else {
        Cookies.remove('remember-me');
        expires = addHours(new Date(), 2);
      }
      this.token = accessToken;
      this.user.role = userType;
      this.user.name = userName;
      this.user.client = client;
      this.user.uid = uid;
      this.user.id = userId;
      this.user.profileId = profileId;
      this.user.personId = personId;
      Cookies.set('access-token', accessToken, { expires });
      Cookies.set('user-type', userType, { expires });
      Cookies.set('user-name', userName, { expires });
      Cookies.set('user-id', userId, { expires });
      Cookies.set('user-profile-id', profileId, { expires });
      Cookies.set('user-person-id', personId, { expires });
      Cookies.set('client', client, { expires });
      Cookies.set('uid', uid, { expires });
    },
  },
});
