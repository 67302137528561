import qs from 'qs';

/**
 * Helper for generation url with query
 * @param {string} url
 * @param {string} query
 */
export const queryToUrl = (url, query) => {
  url = url + '?' + qs.stringify(qs.parse(query), { arrayFormat: 'brackets' });
  return url;
};

/**
 * Helper to generate random color for charts
 * @returns string
 */
export const getRandomColor = () => {
  let colorVal = Math.floor(Math.random() * 16777215).toString(16);
  if (colorVal.length === 5) colorVal += '0';
  return `#${colorVal}`;
};

/**
 * Helper to clone object
 * @param {Object} object
 * @returns Object
 */
export const cloneDeep = object => {
  return JSON.parse(JSON.stringify(object));
};

/**
 * Helper to truncate string
 * @param {string} text
 * @param {number} limit
 * @returns string
 */
export const truncate = (text, limit = 30) => {
  if (text.length - 3 >= limit) {
    return text.substring(0, limit) + '...';
  } else {
    return text;
  }
};

/**
 * Helper to format number
 * @example 5678434.54 -> '5 678 434.54'
 * @param {number} number
 * @return {string} formatted number
 */
export const formatNumber = number => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

/**
 * Helper to format cents to major unit with subunit after a dot.
 * The major unit is separated with commas every third digit.
 * @example 567843454 -> '5,678,434.54'
 * @param {number} cents
 * @return {string} major unit
 */
export const centsToMajorUnit = cents => {
  const parts = (cents / 100).toFixed(2).split('.');
  parts[0] = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  return parts.join('.');
};

/**
 * Helper to parse JSON
 * @param {string, Object} data
 * @return {Object} parsed json data
 */
export const parseJSON = data => {
  if (data) {
    if (typeof data === 'string') {
      const dataObj = JSON.parse(data);
      if (Object.keys(dataObj).length !== 0) {
        return dataObj;
      } else {
        return null;
      }
    } else {
      return data;
    }
  }
  return null;
};

/**
 * Helper to check if given string is a valid URL
 * @param {string} string
 * @return {Boolean} boolean
 */
export const checkValidUrl = string => {
  try {
    new URL(string);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Helper to generate display link of SVG image from provided URL of application/octet-stream content-type file,
 * which is not displayable by default in the browser (checked on Chrome 107).
 * If URL provides to other file type than octet-stream SVG, the value is just returned.
 * @param {string} url
 * @returns {Promise<string>} A Promise that contains a string of URL when fulfilled.
 */
export const getImageUrl = async url => {
  const isValidSvgUrl = url && url !== null && url?.includes('svg');
  const hasOctetStream = url?.includes('octet-stream');

  if (!(isValidSvgUrl && hasOctetStream)) {
    return url;
  }

  try {
    const mime = require('mime/lite');
    const imageType = { type: mime.getType('svg') };
    const imageResponse = await fetch(url);
    const blob = new Blob([await imageResponse.blob()], imageType);
    const imgSrc = URL.createObjectURL(blob);
    return imgSrc;
  } catch (e) {
    console.error(e);
  }
};
