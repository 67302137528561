import http from '../http';

// GET
export const getPeoplePersonalInformation = (profileId, config) => {
  const url = `/api/v1/people/${profileId}`;
  return http.get(url, config);
};

// PATCH
export const patchPeoplePersonalInformation = (profileId, body, config) => {
  const url = `/api/v1/people/${profileId}`;
  return http.patch(url, body, config);
};
