import http from '../http';

// GET
export const getInvestorInvestedAmount = (profileId, productId, config) => {
  const url = `/api/v1/profiles/investors/${profileId}/investments/${productId}`;
  return http.get(url, config);
};

export const getCompanyInvestedAmount = (profileId, productId, config) => {
  const url = `/api/v1/profiles/companies/${profileId}/investments/${productId}`;
  return http.get(url, config);
};

export const getInvestorInvestments = (profileId, config) => {
  const url = `/api/v1/profiles/investors/${profileId}/investments`;
  return http.get(url, config);
};

export const getCompanyInvestments = (profileId, config) => {
  const url = `/api/v1/profiles/companies/${profileId}/investments`;
  return http.get(url, config);
};

export const getInvestorCurrentInvestments = (profileId, config) => {
  const url = `/api/v1/profiles/investors/${profileId}/current_investments`;
  return http.get(url, config);
};

export const getInvestorPendingInvestments = (profileId, config) => {
  const url = `/api/v1/profiles/investors/${profileId}/pending_investments`;
  return http.get(url, config);
};

export const getCompanyCurrentInvestments = (profileId, config) => {
  const url = `/api/v1/profiles/companies/${profileId}/current_investments`;
  return http.get(url, config);
};

export const getCompanyPendingInvestments = (profileId, config) => {
  const url = `/api/v1/profiles/companies/${profileId}/pending_investments`;
  return http.get(url, config);
};

export const getInvestorInfo = (profileId, config) => {
  const url = `/api/v1/profiles/investors/${profileId}`;
  return http.get(url, config);
};

export const getCompanyInfo = (profileId, config) => {
  const url = `/api/v1/profiles/companies/${profileId}`;
  return http.get(url, config);
};

export const getAdvisorInfo = (profileId, config) => {
  const url = `/api/v1/profiles/advisors/${profileId}`;
  return http.get(url, config);
};

export const getCompanyStartupImage = (profileId, config) => {
  const url = `/api/v1/profiles/companies/${profileId}/image`;
  return http.get(url, config);
};

export const getInvestorStartupImage = (profileId, config) => {
  const url = `/api/v1/profiles/investors/${profileId}/image`;
  return http.get(url, config);
};

export const getAdvisorBankAccountInfo = (profileId, config) => {
  const url = `/api/v1/profiles/advisor_bank_accounts/${profileId}`;
  return http.get(url, config);
};

// PATCH
export const patchInvestorInfo = (profileId, body, config) => {
  const url = `/api/v1/profiles/investors/${profileId}`;
  return http.patch(url, body, config);
};

export const patchCompanyInfo = (profileId, body, config) => {
  const url = `/api/v1/profiles/companies/${profileId}`;
  return http.patch(url, body, config);
};

export const patchAdvisorInfo = (profileId, body, config) => {
  const url = `/api/v1/profiles/advisors/${profileId}`;
  return http.patch(url, body, config);
};

export const patchAdvisorBankAccountInfo = (profileId, body, config) => {
  const url = `/api/v1/profiles/advisor_bank_accounts/${profileId}`;
  return http.patch(url, body, config);
};

// DELETE
export const deleteInvestorPendingInvestment = (
  profileId,
  investmendId,
  config
) => {
  const url = `/api/v1/profiles/investors/${profileId}/pending_investments/${investmendId}`;
  return http.delete(url, config);
};

export const deleteCompanyPendingInvestment = (
  profileId,
  investmendId,
  config
) => {
  const url = `/api/v1/profiles/companies/${profileId}/pending_investments/${investmendId}`;
  return http.delete(url, config);
};

export const deleteAdvisorDocument = (profileId, fieldName, fileId, config) => {
  const url = `/api/v1/profiles/advisors/${profileId}/${fieldName}/${fileId}`;
  return http.delete(url, config);
};

export const deleteCompanyDocument = (profileId, fieldName, fileId, config) => {
  const url = `/api/v1/profiles/companies/${profileId}/${fieldName}/${fileId}`;
  return http.delete(url, config);
};

export const deleteInvestorDocument = (
  profileId,
  fieldName,
  fileId,
  config
) => {
  const url = `/api/v1/profiles/investors/${profileId}/${fieldName}/${fileId}`;
  return http.delete(url, config);
};
